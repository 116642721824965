import { PlusOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import moment from "moment";
import React, { useState, useMemo, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { handleFetchApi } from "../../../configs/apiRequest";
import Loading from "../../../components/Loading";
import NoData from "../../../components/NoData";
import CreateDailyReport from "./CreateDailyReport";
import Report from "../../../components/Report";
import { useTranslation } from "react-i18next";
import { StoreContext } from "../../../stores";
import ReportHistory from "../../../components/ReportHistory";
import bad from "../../../assets/images/bad.png";
import { AREPORT } from "../../../apis/report";

function TeacherReport() {
    const { me, selectedClass } = useContext(StoreContext);

    const { t } = useTranslation();
    const today = useMemo(() => moment().format("YYYY-MM-DD"), []);
    const [modal, setModal] = useState({
        open: false,
        initData: null,
        type: "CREATE",
    });
    const handleOpenModal = () => setModal((prev) => ({ ...prev, open: true, initData: {} }));

    const handleCloseModal = ({ initData }) => {
        setModal((prev) => ({ ...prev, open: false, initData: initData }));
    };
    const handleUpdateRecord = ({ initData }) => {
        setModal((prev) => ({ ...prev, open: true, initData: initData, type: "UPDATE" }));
    };

    const { data: reportData, isLoading } = useQuery(
        [`teacher_report${selectedClass?.id}`],
        async () => {
            const response = await handleFetchApi(
                AREPORT.search({ params: `?teacher_id=${me?.id}&course_id=${selectedClass?.courseId}&report_date=${today}` })
            );
            return response?.data[0];
        },
        {
            staleTime: 300000,
            cacheTime: 300000,
            refetchOnWindowFocus: false,
        }
    );
    const formattedDate = useMemo(() => moment().format("DD/MM/YYYY"), []);
    return (
        <div className="loading_container" style={{ opacity: reportData?.is_confirmed ? 1 : 0.8 }}>
            <Typography.Title level={4} className="flex__between__center">
                {t("date")} {formattedDate} {reportData && !reportData?.is_confirmed && "( Chờ duyệt )"}
                <Button onClick={handleOpenModal} icon={<PlusOutlined />} type="primary" className="--md">
                    {t("create_today_comment")}
                </Button>
            </Typography.Title>

            {isLoading ? <Loading /> : reportData ? <Report reportData={reportData} /> : <NoData img={bad} imageWidth={100} text={t("no_report_data_today")} />}

            <br />
            <ReportHistory
                initData={modal?.initData}
                onUpdate={handleUpdateRecord}
                params={`?teacher_id=${me?.id}&course_id=${selectedClass?.courseId}&class_id=${selectedClass?.id}`}
            />
            <CreateDailyReport type={modal?.type} initData={modal?.initData} open={modal?.open} close={handleCloseModal} />
        </div>
    );
}

export default React.memo(TeacherReport);
