export const AREPORT = {
    search: ({ params }) => ({
        method: "GET",
        url: `/class-report/search${params ?? ""}`,
    }),
    getByPrincipal: (params) => ({
        url: `/class-report/principal/class-report${params ?? ""}`,
    }),
    getByHomeTeacher: (params) => ({
        url: `/class-report/home-teacher/class-report${params ?? ""}`,
    }),
    setHomeTeacher: () => ({
        method: "PUT",
        url: `/class/home-teacher`,
    }),
    accept: ({ reportId }) => ({
        method: "PUT",
        url: `/class-report/admin/confirmation/${reportId}`,
    }),
    comment: ({ reportId, data }) => ({
        method: "PUT",
        url: `/class-report/admin/comment/${reportId}`,
        data,
    }),
    delete: ({ reportId }) => ({
        method: "DELETE",
        url: `/class-report/${reportId}`,
    }),
    update: ({ reportId, data }) => ({
        method: "PUT",
        url: `/class-report/${reportId}`,
        data,
    }),
    createDaily: ({ data }) => ({
        method: "POST",
        url: "/class-report",
        data,
    }),
};
